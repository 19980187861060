import {useRef, useState} from "react";
import {classNames} from "../../utils/componentUtils";
import Decimal from "decimal.js";
import {RiCloseLine, RiDeleteBinLine} from "react-icons/ri";
import neverLandGameData from "../../utils/NeverLandGameData";
import neverLandUtils from "../../utils/NeverLandUtils";
import {useTicketList} from "../../utils/useTicketList";


const _displayItemOnceLimit = 30; // 카트사이드바에 한번에 띄울 티켓 수
const TicketCard = ({
                      batchPrice,
                      isPoweredObject,
                      selectTicketDataList,
                      customKeyPadInstance,
                    }) => {
  const [itemLimit, setItemLimit] = useState(_displayItemOnceLimit);
  const [isSelectedTickets, setIsSelectedTickets] = useState([]);
  const [purchaseAmount, handleInputChange, resetAmount] = useTicketList(neverLandGameData.ticketData, {});
  // const [amountOnChange, setAmountOnChange] = useState(false);
  const [currentData, setCurrentData] = useState({list: []});
  // const [inputPosition, setInputPosition] = useState({top: 0, left: 0});

  const ticketInputRef = useRef([]);

  // 구매금액 일괄입력 관련 우선순위
  const {isPoweredRequest, disablePowered} = isPoweredObject;
  disablePowered();

  const handleSelectTicketChange = (id) => {
    // 티켓 선택
    setIsSelectedTickets((prevTickets) => {
      if (prevTickets.includes(id)) {
        return prevTickets.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevTickets, id];
      }
    })
  }

  function removeCurrentItem(card, allotId) {
    // 티켓내 경기 삭제
    let fSelectedGame = card.selectGame.filter((game) => game.allotId !== allotId);
    if (fSelectedGame.length === 0) {
      neverLandGameData.ticketData = neverLandGameData.ticketData.filter((_card) => _card.id !== card.id);
    } else {
      card.selectGame = fSelectedGame;
      card.totalRate = neverLandGameData.getRatingValue(card);
    }
    setIsSelectedTickets(prevTickets => {
      return [...prevTickets];
    });
  }

  if (currentData.list !== neverLandGameData.ticketData) {
    currentData.list = neverLandGameData.ticketData;
    setItemLimit(_displayItemOnceLimit);
    if (isSelectedTickets.length > 0)
      setIsSelectedTickets([]);
  }
  let amount;
  if (isSelectedTickets !== null && isSelectedTickets.length > 0) {
    let _current_selected_ticket = [];
    for (let card of currentData.list) {
      if (isSelectedTickets.includes(card.id)) {
        amount = purchaseAmount[card.id] || 0;
        if (isPoweredRequest) {
          amount = batchPrice;
        }
        if (amount === 0) {
          if (card.currentAmount)
            amount = card.currentAmount;
        }
        _current_selected_ticket.push({
          amount: amount,
          selectedGame: card.selectGame,
          id: card.id,
          totalRate: card.totalRate,
          ticketCount: 1
          // ticketCount: selectedSheetQuantity[card.id] || 1
        });
      }
    }
    selectTicketDataList(_current_selected_ticket);
  } else {
    selectTicketDataList([]);
  }

  function deleteTicketCard(card) {
    // 선택한 티켓 삭제
    neverLandGameData.ticketData = neverLandGameData.ticketData.filter((_card) => _card.id !== card.id);

    setIsSelectedTickets(prevTickets => {
      return [...prevTickets.filter((card) => card.id !== card.id)];
    });
  }

  function _getGameRate(length, amount, rate) {
    // 소수점 계산 오류 방지
    if (rate === null) return 0;
    if (amount === null) return 0;
    const rateDecimal = new Decimal(rate);
    let _rateText = rateDecimal.times(amount).toString();
    return Number(_rateText);
  }

  let itemCount = 0;
  return (
      <div className="flex flex-col justify-center">
        {currentData.list?.map((card, index) => {
          // limit 값을 넘어가면 다음보기띄우고, 다음보기 누르면 데이터 더 보여주기
          if (itemCount++ >= itemLimit) {
            if (itemCount - 1 === itemLimit) {
              return (
                  <button className="button-style mx-24 py-1"
                          onClick={() => setItemLimit(prevState => prevState + _displayItemOnceLimit)}>
                    더보기
                  </button>
              );
            }
            return <></>;
          }

          if (card.selectGame.length === 0)
            return <></>;

          let amount = purchaseAmount[card.id] || 0;

          if (isPoweredRequest) {
            amount = batchPrice;
            purchaseAmount[card.id] = amount;
          } else if (amount === 0) {
            // if (card.currentAmount)  = { if (card.currentAmount!==undefined || card.currentAmount!==null|| card.currentAmount!==0  ) {
            if (card.currentAmount !== undefined) {
              amount = card.currentAmount;
            } else {
              amount = batchPrice;
            }
          }
          return (
              <div key={card.id}
                   className="px-1 mb-4 bg-white shadow-md flex flex-row border border-gray-300 text-sm mr-2">
                <div className="py-4 px-2 w-full">
                  <div className="flex flex-row justify-between items-center">
                    <p className="font-semibold text-right mt-2"> {card.selectGame.length}경기 선택 </p>

                    <div className="flex flex-row justify-end">
                      <button className="bg-rose-600 rounded-md"
                              onClick={() => deleteTicketCard(card)}
                      >
                        <RiDeleteBinLine className="w-7 h-auto text-white p-1"/>
                      </button>
                    </div>
                  </div>

                  <div>
                    {/* <-- 테이블 헤더 --> */}
                    <div
                        className="font-semibold w-full mt-3 text-center bg-gray-900 text-white flex py-1 whitespace-nowrap">
                      <div className="w-20">경기</div>
                      <div className="w-36">홈팀</div>
                      <div> :</div>
                      <div className="w-24">원정팀</div>
                      <div className="w-16">예상</div>
                      <div className="w-20 text-right">배당률</div>
                      <div className="w-5"></div>
                    </div>
                    {/* <-- 테이블 바디 --> */}
                    {(
                        <div key={card.id}>
                          {card.selectGame?.map((game) => {
                            let isFixedData = neverLandGameData.fixedSelectedAllotData.includes(game.itemKey);
                            return (
                                <div key={game.allotId}
                                     className={classNames(isFixedData ? "bg-amber-300" : "",
                                         "w-full flex text-center items-center px-1 py-2 text-black border-t border-gray-300 whitespace-nowrap text-xs font-bold")}>
                                  {/* 경기번호 */}
                                  <div className="w-16 flex flex-row justify-center">
                                    <div>
                                      {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) &&
                                          <p>
                                            {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) ? game.gameNumber.replace("⒮", "S").substring(0, 1) : ""}
                                          </p>
                                      }
                                    </div>
                                    <p className={classNames(game.gameNumber.includes('*') ? "" : "ml-1")}>
                                      {(game.gameNumber.includes("U") || game.gameNumber.includes("H") || game.gameNumber.includes("⒮")) ? game.gameNumber.substring(1) : game.gameNumber}
                                    </p>
                                  </div>
                                  {/* 홈팀 */}
                                  <div className="w-44 pl-3 text-left whitespace-nowrap">
                                    <p>{game.homeTeam}</p>
                                  </div>

                                  <div>:</div>

                                  {/* 원정팀 */}
                                  <div className="w-28 ml-2 mr-1 text-left">{game.awayTeam}</div>
                                  {/* 예상 */}
                                  <div
                                      className="w-12 text-left"> {neverLandGameData.getPrediction(game.gameNumber, game.prediction)} </div>
                                  {/* 배당 */}
                                  <div className="w-20">{game.rate}</div>

                                  {/*카드 삭제 버튼*/}
                                  <button onClick={() => removeCurrentItem(card, game.allotId)}
                                          className="bg-gray-500 rounded">
                                    <RiCloseLine className="h-4 w-auto text-white"/>
                                  </button>
                                </div>
                            );
                          })}
                        </div>
                    )}
                    <div className="border-t border-gray-400"></div>
                  </div>

                  <div className="flex flex-row justify-between items-center my-2 font-semibold">
                    <div
                        className="flex flex-row min-w-14 justify-center mr-2 bg-amber-300 text-black font-bold px-1 py-0.5 rounded-lg text-sm">
                      <p className="mr-1">{card.totalRate}</p>
                      <p>배</p>
                    </div>

                    <div className="w-4/5 flex flex-row justify-between items-center ml-1 mr-1">
                      <label className="text-right" htmlFor="amount">개별투표금액 :</label>

                      <div className="flex flex-row">
                        <div
                            className="flex flex-row rounded-md border-0 ring-1 ring-inset ring-gray-300 py-0.5 pl-2 ml-1 mr-2">
                          <input
                              readOnly
                              className="text-left w-[4.5rem] font-semibold text-gray-900 caret-white"
                              id={`amount-${index}`}
                              ref={el => (ticketInputRef.current[index] = el)} // 각 인풋에 개별 ref 할당
                              value={
                                // (amountOnChange ? (card.currentAmount = amount) :
                                (card.currentAmount = amount).toLocaleString()
                                // )
                              }
                              onFocus={(e) => {
                                if (customKeyPadInstance) customKeyPadInstance.showKeyPad((value) => {
                                  let newValue;
                                  let temp = card.currentAmount.toString();
                                  if (value === 'delete') {
                                    if (temp.length === 1) {
                                      newValue = 0;
                                    } else if (temp.length > 1) {
                                      newValue = neverLandUtils.utils.textToNumber(temp.substring(0, temp.length - 1));
                                    }
                                  } else {
                                    newValue = neverLandUtils.utils.textToNumber(temp + value);
                                  }
                                  card.currentAmount = newValue;
                                  handleInputChange(e, card.id, newValue.toString());
                                }, ticketInputRef.current[index]);
                              }}
                              onBlur={() => {
                                if (customKeyPadInstance) customKeyPadInstance.hideKeyPad();
                              }}
                              // onChange={(e) => {
                              //   card.currentAmount = null;
                              //   // handleInputValueOnChange(ticketInputRef.current[index], e.target.value, card.id); // 각 티켓 인풋에 대한 값 전달
                              // }}
                          />

                          <button type="reset">
                            <RiCloseLine className="h-4 w-auto px-1"
                                         onClick={() => {
                                           card.currentAmount = 0;
                                           resetAmount(card.id)
                                         }}/>
                          </button>
                        </div>
                        <p>원</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between items-center font-semibold mr-1">
                    <p className="w-1/2 text-right ml-1.5">예상 적중금 :</p>
                    <div className="flex flex-row col-span-6">
                      <p className="mr-3">
                        {(_getGameRate(card.selectGame.length, amount, card.totalRate || 0)).toLocaleString() || 0}
                      </p>
                      <p>원</p>
                    </div>
                  </div>
                  {/* <-- 경고 알림 문구 (출력에 영향 X) --> */}
                  {card.totalRate > 100 &&
                      <p className="text-rose-700 font-bold py-0.5 bg-red-200 pl-2 my-0.5">
                        ※ 100 배당이 초과하였습니다.
                      </p>
                  }

                  {_getGameRate(card.selectGame.length, amount, card.totalRate || 0) > 2000000 &&
                      <p className="text-rose-700 font-bold py-0.5 bg-red-200 pl-2 my-0.5">
                        ※ 적중금이 200만원을 초과하였습니다.
                      </p>
                  }
                </div>
              </div>
          )
        })}
      </div>
  )
}
export default TicketCard;
