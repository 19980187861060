import axios from "axios";

const {REACT_APP_API_BASE_URL, REACT_APP_API_BASE_URL2} = process.env;

function _getBaseURL(url) {
  let currentPath = window.location.href;

  if (currentPath.includes("https://") && url.includes("http://")) {
    url = "https://" + new URL(currentPath).hostname + "/api/";
  } else {
  }
  return url;
}


export const fetcher = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_BASE_URL)
});

export const fetcher2 = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_BASE_URL2)
});
