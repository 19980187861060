import React, {useState} from "react";
import {ModalHandler, OpenModalHandle} from "../components/modal/ModalHandler";
import GameAnalysisInfoCard from "../components/card/GameAnalysisInfoCard";
import SameGameRateInfoTable from "../components/table/SameGameRateInfoTable";
import {getImage} from "../constants/constants";

const GameAnalysisPage = () => {
  const [selectedRate, setSelectedRate] = useState({value: null, index: null});

  const OpenModal = OpenModalHandle.create();

  const isSelected = (value, index) => {  // 선택한 배당
    return selectedRate.value === value && selectedRate.index === index;
  }

  // useEffect(() => {
  //   getGameAnalysisInfo();
  // }, [])
  //
  //
  // const getGameAnalysisInfo = () => {
  //  //경기 분석 정보 받아오기
  //   fetcher.get(GET_ANALYSIS_INFO_LIST_API + `?dataId=${null}`)
  //       .then(res => {
  //         const {header, result} = res.data;
  //         if (res.status === 200) {
  //           if (header.type === 'success') {
  //
  //           } else {
  //             // OpenModal.openWarningModal('경기 분석 정보 불러오기', header.message);
  //           }
  //         } else {
  //           OpenModal.openWarningModal(res.status, '데이터를 불러오는데 실패하였습니다.');
  //         }
  //       }).catch(err => {
  //     console.log(err)
  //   })
  // }

  return (
      <div className="w-[50rem] h-full flex flex-col justify-center px-5">
        <ModalHandler initModalHandle={OpenModal}/>

        <div className="w-full h-full rounded-lg flex flex-col">
          {/* <-- 경기 팀, 배당 정보 --> */}
          <div className="flex-none">
            <GameAnalysisInfoCard setSelectedRate={setSelectedRate} isSelected={isSelected}/>
          </div>

          {/* <-- 같은 배당 경기 분석 --> */}
          <div className="flex-grow overflow-y-auto mt-5">
            <img src={getImage('soccer_ground')} className="w-full h-auto"/>
            {selectedRate && <SameGameRateInfoTable selectedRate={selectedRate}/>}
          </div>
        </div>
      </div>
  );
}
export default GameAnalysisPage;