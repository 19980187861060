import {_customPickData} from "../_dummyData";
import {useEffect, useState} from "react";
import {ModalHandler, OpenModalHandle} from "../components/modal/ModalHandler";
import fetcher from "../utils/fetcher";
import {GET_CUSTOM_PICK_GAME_LIST_API} from "../constants/api_constants";

const CustomPickPage = () => {
  const [customPickGameList, setCustomPickGameList] = useState([]);
  const [selectedGameList, setSelectedGameList] = useState([]);
  const OpenModal = OpenModalHandle.create();

  // useEffect(() => {
  //   getCustomPickGameList();
  // }, []);

  // const getCustomPickGameList = () => { // 커스텀픽 경기 리스트 받아오기
  //   fetcher.get(GET_CUSTOM_PICK_GAME_LIST_API)
  //       .then(res => {
  //         const {header, result} = res.data;
  //         if (res.status === 200) {
  //           if (header.type === 'success') {
  //             setCustomPickGameList(result);
  //           } else {
  //             // OpenModal.openWarningModal('커스텀픽 경기 불러오기', header.message);
  //           }
  //         } else {
  //           OpenModal.openWarningModal(res.status, '데이터를 불러오는데 실패하였습니다.');
  //         }
  //       }).catch(err => {
  //     console.log(err)
  //   })
  // }

  const handleSelectList = (id) => {  // 선택한 경기 조합
    setSelectedGameList((prevList) => {
      // 이미 선택한거면 리스트에서 빼기
      if (prevList.includes(id)) {
        return prevList.filter(game => game !== id);
      } else {
        // 리스트에 추가 : 이미 5개 선택했으면 경고모달, 아니면 추가
        if (prevList.length > 4) {
          OpenModal.openWarningModal('경기 선택 초과', '5경기까지 선택 가능합니다.');
          return prevList;
        }
        return [...prevList, id];
      }
    });
  };

  return (
      <div className="flex flex-col h-full items-center px-[8rem]">
        <ModalHandler initModalHandle={OpenModal}/>

        <div className="w-full h-[3rem] mt-5 flex flex-row justify-centeru items-center">
          <p className="text-white text-lg unde">※ 5경기를 선택 후 조합을 생성 하세요 .</p>
          <button className="button-style bg-yellow-300 text-gray-900 mx-5"
                  onClick={()=>OpenModal.openWarningModal('조합 생성', '현재 미구현 기능입니다.')}
          >
            조합 생성
          </button>
          <button className="button-style bg-lime-300 text-black"
                  onClick={()=>OpenModal.openWarningModal('이어서 인쇄', '현재 미구현 기능입니다.')}
          >
            이전 조합 이어서 인쇄
          </button>
        </div>

        <div className="w-full h-[calc(100%-7rem)] mt-7 mb-16  overflow-y-auto px-1">
          {/* <-- 테이블 헤더 --> */}
          <div
              className="sticky top-0 font-semibold w-full h-[3rem] text-center text-lg bg-yellow-300 text-gray-900 flex py-1 justify-center items-center border-b border-gray-900">
            <div className="w-[10rem]">경기날짜</div>
            <div className="w-[7rem]">경기번호</div>
            <div className="w-[10rem]">홈팀</div>
            <div className="w-[2rem]"> :</div>
            <div className="w-[10rem]">원정팀</div>
            <div className="w-[7rem]">유형</div>
            <div className="w-[7rem]">승배당</div>
            <div className="w-[7rem]">무배당</div>
            <div className="w-[7rem]">패배당</div>
          </div>

          {/* <-- 테이블 바디 --> */}
          <div className="h-[calc(100%-7rem)]">
            {/*{customPickGameList.map((data) => {*/}
            {_customPickData.map((data) => {
              return (
                  <div>
                    {/* <-- 리그  --> */}
                    <div key={data.league}
                         className="w-full flex items-center text-xl font-bold py-1 px-10 justify-start bg-gray-300 border-y border-gray-900">
                      {data.league}
                    </div>

                    {/* <--  경기  --> */}
                    <div>
                      {data.gameData.map((game, index) => {
                        return (
                            <div key={index}
                                 className={`${selectedGameList.includes(data.league + index) ? 'bg-lime-300' : 'bg-white text-black'} w-full flex text-center items-center p-2 border-t border-gray-300 justify-center`}
                                 onClick={() => handleSelectList(data.league + index)}
                            >

                              {/* 경기날짜 */}
                              <div className="w-[10rem]">
                                <p>{game.date}</p>
                              </div>

                              {/* 경기번호 */}
                              <div className="w-[7rem]"> {game.gameNumber} </div>

                              {/* 홈팀 */}
                              <div className="w-[10rem] flex flex-row justify-center items-center">
                                <img className="w-8 h-8 mr-2"
                                     src="https://s.yimg.com/iu/api/res/1.2/q1XR8KpfDQAnz394mDf83g--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD02MDt3PTYw/https://s.yimg.com/cv/apiv2/default/soccer/20181130/500x500/BorussiaDortmund2_wbg.png"
                                     alt="Borussia Dortmund"/>
                                {game.homeTeam}
                              </div>

                              <div className="w-[2rem]"> :</div>

                              {/* 원정팀 */}
                              <div className="w-[10rem] flex flex-row justify-center items-center">
                                <img className="w-8 h-8 mr-2"
                                     src="https://s.yimg.com/iu/api/res/1.2/r4GQFLQcAQcHz_4PC_HcCw--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD02MDt3PTYw/https://s.yimg.com/cv/apiv2/default/soccer/20181127/500x500/RealMadrid_wbg.png"
                                     alt="Real Madrid"/>
                                {game.awayTeam}
                              </div>

                              {/* 유형 */}
                              <div
                                  className="w-[5rem] mx-[1rem] bg-gray-200 rounded-lg inline-block text-black border border-gray-300"> {game.extraText} </div>

                              {/* 승배당 */}
                              <div className="w-[7rem]">
                                <p className={`${game.result === 'w' ? 'bg-amber-300 px-2 text-gray-900 inline-block w-auto rounded-lg font-bold' : ''}`}>
                                  {game.win}
                                </p>
                              </div>
                              {/* 무배당 */}
                              <div className="w-[7rem]">
                                <p className={`${game.result === 'd' ? 'bg-amber-300 text-gray-900 px-2 inline-block w-auto rounded-lg font-bold' : ''}`}>
                                  {game.draw}
                                </p>
                              </div>
                              {/* 패배당 */}
                              <div className="w-[7rem]">
                                <p className={`${game.result === 'l' ? 'bg-amber-300 px-2 text-gray-900 inline-block w-auto rounded-lg font-bold' : ''}`}>
                                  {game.lose}
                                </p>
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
              )
            })}
            {/*<div className="border-t border-gray-400 mt-1"></div>*/}
          </div>
        </div>
      </div>
  );
}
export default CustomPickPage;