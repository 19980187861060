import {_rateData} from "../../_dummyData";
import React from "react";


const GameAnalysisInfoCard = ({setSelectedRate, isSelected}) => {


  return (
      <div className="w-full h-[13rem] bg-white flex flex-row justify-between items-center px-7 rounded-lg shadow-md">
        {/*홈팀*/}
        <div className="flex flex-col justify-center items-center text-xl font-semibold">
          <p>Brewers</p>
          <img
              src="https://s.yimg.com/iu/api/res/1.2/EVIsUTCF9j4zSmwcA5.r.w--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD02MDt3PTYw/https://s.yimg.com/cv/apiv2/default/mlb/20200323/500x500/brewers_wbg.png"
              alt="Brewers"
              className="w-16 h-auto pt-5"/>
        </div>

        {/*배당정보*/}
        <div>
          <div className="grid grid-cols-4 gap-4 border-b border-gray-500 text-center font-semibold">
            <p>유형</p>
            <p>승</p>
            <p>무</p>
            <p>패</p>
          </div>

          <div>
            {_rateData.map((game, index) => {
              return (
                  <div className="grid grid-cols-4 gap-4 py-1 text-center" key={index}>
                    <span className="px-2 bg-lime-200 rounded-lg">{game.name}</span>
                    <button
                        className={`w-16 rounded-lg mx-auto ${isSelected(game.rateWin, index) ? "bg-amber-300 text-gray-900 font-bold" : "bg-gray-200"}`}
                        disabled={game.rateWin === '-'}
                        onClick={() => setSelectedRate({value: game.rateWin, index: index})}
                    >{game.rateWin}</button>
                    <button
                        className={`w-16 rounded-lg mx-auto ${isSelected(game.rateDraw, index) ? "bg-amber-300 text-gray-900 font-bold" : "bg-gray-200"}`}
                        disabled={game.rateDraw === '-'}
                        onClick={() => setSelectedRate({value: game.rateDraw, index: index})}
                    >{game.rateDraw}</button>
                    <button
                        className={`w-16 rounded-lg mx-auto ${isSelected(game.rateLose, index) ? "bg-amber-300 text-gray-900 font-bold" : "bg-gray-200"}`}
                        disabled={game.rateLose === '-'}
                        onClick={() => setSelectedRate({value: game.rateLose, index: index})}
                    >{game.rateLose}</button>
                  </div>
              )
            })}
          </div>
        </div>

        {/*원정팀*/}
        <div className="flex flex-col justify-center items-center text-xl font-semibold">
          <p>Giants</p>
          <img
              src="https://s.yimg.com/iu/api/res/1.2/eqEBX2oPUVRECIOj0sTkuQ--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD02MDt3PTYw/https://s.yimg.com/cv/apiv2/default/mlb/20190409/500x500/giants_s.png"
              alt="Giants" className="w-16 h-auto pt-5" loading="lazy"/>
        </div>
      </div>
  )
}
export default GameAnalysisInfoCard;