import {_happyPickData} from "../_dummyData";
import {useEffect, useState} from "react";
import {ModalHandler, OpenModalHandle} from "../components/modal/ModalHandler";
import fetcher from "../utils/fetcher";
import {GET_HAPPY_PICK_LIST_API} from "../constants/api_constants";

const HappyPickPage = () => {
  const [happyPickGameList, setHappyPickGameList] = useState([]);
  const OpenModal = OpenModalHandle.create();

  // useEffect(() => {
  //   getHappyPickGameList();
  // }, []);

  // const getHappyPickGameList = () => { // 행복픽 경기 리스트 받아오기
  //   fetcher.get(GET_HAPPY_PICK_LIST_API)
  //       .then(res => {
  //         const {header, result} = res.data;
  //         if (res.status === 200) {
  //           if (header.type === 'success') {
  //             setHappyPickGameList(result);
  //           } else {
  //             // OpenModal.openWarningModal('행복픽 경기 불러오기', header.message);
  //           }
  //         } else {
  //           OpenModal.openWarningModal(res.status, '데이터를 불러오는데 실패하였습니다.');
  //         }
  //       }).catch(err => {
  //     console.log(err)
  //   })
  // }

  const isPrintable = (gameData) => {
    return gameData.every(game => game.state === '경기전');
  }

  return (
      <div className="flex flex-col w-full h-[calc(100%-3rem)] items-center px-3">
        <ModalHandler initModalHandle={OpenModal}/>
        
        <div className="w-full h-full my-5 px-[7rem] flex flex-col overflow-y-auto">
          {/*{happyPickGameList.map((data) => {*/}
          {_happyPickData.map((data) => {
            return (
                <div className="w-full h-auto bg-white my-5 flex flex-col justify-between rounded-lg">
                  {/* <-- 상단 행복픽 정보 --> */}
                  <div
                      className="w-full flex flex-row font-semibold justify-between items-center bg-yellow-300 rounded-t-lg py-2 px-[10rem]">
                    <div className="flex flex-row justify-center items-center">
                      <p className="bg-gray-900 text-yellow-300 px-2 py-1 rounded-lg">{data.gameRound} 회차</p>
                      <p className="px-5">예상 배당 : {data.predictionRate}</p>
                    </div>

                    <p>발행일 : 2024-09-19</p>
                  </div>

                  {/* <-- 하단 경기 정보 --> */}
                  <div className="w-full py-3 flex flex-row justify-center">
                    <div>
                      {data.gameData.map((game) => {
                        return (
                            <div className="text-center flex my-3 justify-center items-center">
                              <div className="mx-3">{game.gameNumber}</div>

                              <div className="w-[10rem]">{game.date}</div>

                              <div className="w-[8rem] flex flex-row justify-center items-center">
                                <img className="w-8 h-8 mr-3"
                                     src="https://s.yimg.com/iu/api/res/1.2/ITWXG12TLE7sK.jYdrTl6A--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD03Mjt3PTcy/https://s.yimg.com/cv/apiv2/default/mlb/20190319/500x500/yankees_wbgs.png"
                                     alt="New York Yankees"/>
                                {game.homeTeam}
                              </div>

                              <p className="w-[1rem]"> vs </p>

                              <div className="w-[8rem] flex flex-row justify-center items-center">
                                <img className="w-8 h-8 mr-3"
                                     src="https://s.yimg.com/iu/api/res/1.2/XrAgOcu4BLsyiKs2VDctEw--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD03Mjt3PTcy/https://s.yimg.com/cv/apiv2/default/20181022/500x500/mariners_wbg.png"
                                     alt="Seattle Mariners"/>
                                {game.awayTeam}
                              </div>

                              <p className="w-[5rem] bg-lime-200 rounded-lg inline-block mr-1">{game.extraText}</p>
                              <p className="w-[4rem] font-bold bg-gray-200 rounded-lg inline-block mx-1">{game.win}</p>
                              <p className="w-[4rem] font-bold bg-gray-200 rounded-lg inline-block mx-1">{game.draw}</p>
                              <p className="w-[4rem] font-bold bg-gray-200 rounded-lg inline-block mx-1">{game.lose}</p>

                              <p className={`${game.state === '경기전' ? 'bg-yellow-200' : game.state === '경기중' ? 'bg-gray-500 text-white'
                                  : 'bg-gray-900 text-yellow-300'} rounded-full ml-2 py-1 w-[5rem]`}>
                                {game.state}
                              </p>
                            </div>
                        )
                      })}
                    </div>

                    {/* <-- 출력 버튼 --> */}
                    {isPrintable(data.gameData) &&
                        <div
                            className="w-[18rem] flex flex-wrap items-center justify-center bg-sky-100 rounded-lg ml-5 shadow-md border border-slate-300">
                          {_buttonData.map((button, index) => {
                            return (
                                <button className="happy-pick-print-btn mx-1.5" key={index}>
                                  {button.name}
                                </button>
                            )
                          })}
                        </div>
                    }
                  </div>
                </div>
            )
          })}
        </div>
      </div>
  );
}
export default HappyPickPage;

const _buttonData = [
  {name: "5천 [ 5 X 1천 ]", value: 5000},
  {name: "1만 [ 5 X 2천 ]", value: 10000},
  {name: "2만 [ 5 X 4천 ]", value: 20000},
  {name: "3만 [ 5 X 6천 ]", value: 30000},
  {name: "5만 [ 5 X 1만 ]", value: 50000},
  {name: "10만 [ 5 X 2만 ]", value: 100000},
  {name: "15만 [ 5 X 3만 ]", value: 150000},
  {name: "50만 [ 5 X 10만 ]", value: 500000},
]