import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import GameAnalysisPage from "../../pages/GameAnalysisPage";
import {RiCloseLine} from "react-icons/ri";

const GameAnalysisModal = ({setShowGameAnalysis}) => {
  const [open, setOpen] = useState(true);
  const closeModal = () => {
    setOpen(false);
    setShowGameAnalysis(false);
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="w-[53rem] h-[100rem] relative transform overflow-hidden rounded-lg pt-2 pb-5 text-left shadow-xl transition-all bg-gray-200">

                  {/* <-- 닫기버튼 --> */}
                  <div className="flex justify-end pr-5 pb-3">
                    <button onClick={closeModal}>
                      <RiCloseLine className="w-10 h-10"/>
                    </button>
                  </div>

                  {/* <-- 경기분석 페이지 가져와서 띄움 --> */}
                  <div className="h-full overflow-y-auto flex items-center justify-center">
                    <GameAnalysisPage/>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default GameAnalysisModal;