import {_sameGameRateData} from "../../_dummyData";
import {useEffect, useState} from "react";
import {GET_SAME_RATE_INFO_LIST_API} from "../../constants/api_constants";
import {fetcher} from "../../utils/fetcher";

const SameGameRateInfoTable = ({selectedRate}) => {
  const [sameRateGameList, setSameRateGameList] = useState([]);

  useEffect(() => {
    if (selectedRate.value !== null) getSameRateGameList();
  }, [selectedRate]);

  const getSameRateGameList = () => { // 선택한 배당과 동일한 경기 리스트 받아오기
    fetcher.get(GET_SAME_RATE_INFO_LIST_API + `?rateNumber=${selectedRate.value}`)
        .then(res => {
          const {header, result} = res.data;
          if (res.status === 200) {
            if (header.type === 'success') {
              setSameRateGameList(result);
            } else {
              console.log('정보 불러오기 실패', res.data);
            }
          } else {
            // OpenModal.openWarningModal(res.status, '데이터를 불러오는데 실패하였습니다.');
          }
        }).catch(err => {
      console.log(err)
    })
  }

  return (
      <div className="w-full h-full bg-white p-2">

        {/* <-- 테이블 헤더 --> */}
        <div className="px-2 py-5 h-full overflow-y-auto">
          <div className="font-semibold w-full mt-3 text-center bg-gray-900 text-white flex py-1 justify-center">
            <div className="w-[10rem]">경기날짜</div>
            <div className="w-[10rem]">홈팀</div>
            <div className="w-[2rem]"> :</div>
            <div className="w-[10rem]">원정팀</div>
            <div className="w-[7rem]">결과</div>
            <div className="w-[7rem]">유형</div>
            <div className="w-[7rem]">승배당</div>
            <div className="w-[7rem]">무배당</div>
            <div className="w-[7rem]">패배당</div>
          </div>

          {/* <-- 테이블 바디 --> */}
          <div>
            {/*{sameRateGameList?.map((data, index) => {*/}
            {_sameGameRateData?.map((data, index) => {
              return (
                  <div key={index}
                       className={`${index % 2 === 1 ? 'bg-gray-100' : 'bg-white'} w-full flex text-center items-center p-2 text-black border-t border-gray-300 justify-center`}>
                    {/* 경기날짜 */}
                    <div className="w-[10rem]">
                      <p>{data.date}</p>
                    </div>

                    {/* 홈팀 */}
                    <div className="w-[10rem]">
                      <p>{data.homeTeam}</p>
                    </div>

                    <div className="w-[2rem]"> :</div>

                    {/* 원정팀 */}
                    <div className="w-[10rem]">{data.awayTeam}</div>

                    {/* 결과 */}
                    <div className="w-[7rem]"> {data.resultText} </div>

                    {/* 유형 */}
                    <div className="w-[7rem]"> {data.extraText} </div>

                    {/* 승배당 */}
                    <div className="w-[7rem]">
                      <p className={`${data.result === 'w' ? 'bg-amber-300 px-2 text-gray-900 inline-block w-auto rounded-lg font-bold' : ''}`}>
                        {data.win}
                      </p>
                    </div>
                    {/* 무배당 */}
                    <div className="w-[7rem]">
                      <p className={`${data.result === 'd' ? 'bg-amber-300 text-gray-900 px-2 inline-block w-auto rounded-lg font-bold' : ''}`}>
                        {data.draw}
                      </p>
                    </div>
                    {/* 패배당 */}
                    <div className="w-[7rem]">
                      <p className={`${data.result === 'l' ? 'bg-amber-300 px-2 text-gray-900 inline-block w-auto rounded-lg font-bold' : ''}`}>
                        {data.lose}
                      </p>
                    </div>
                  </div>
              );
            })}
          </div>
          <div className="border-t border-gray-400 mt-1"></div>
        </div>
      </div>
  )
}
export default SameGameRateInfoTable;