import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {
  ERROR_PAGE, LOADING_PAGE, LOGIN_PAGE, MAIN_TEMP_PAGE, PROTO_GAME_PAGE, SETTING_PAGE
} from "./constants/page_constants";
import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import WindowInterfaces, {forcedDeviceLoginCommit, forcedDeviceLogout} from "./utils/WindowInterfaces";
import {LOGIN_API} from "./constants/api_constants";
import LoadingPage from "./pages/LoadingPage";
import {useEffect} from "react";
import MainPage from "./pages/MainPage";
import {
  accountInfo,
  DEVICE_CODE,
  DEVICE_SETTING, DEVICE_SETTING_DEFAULT_LIST,
  SET_MONEY_BUTTON_LIST,
  STORE_CODE
} from "./constants/constants";
import SettingPage from "./pages/SettingPage";
import neverLandUtils from "./utils/NeverLandUtils";
import {useLocalStorage} from "./utils/useLocalStorage";
import {fetcher} from "./utils/fetcher";

// 📌 전자게시판 화면 사이즈
// 가로모드 : 1830 * 1080 / 세로 모드 : 1030 * 1880

function App() {
  // TODO : 에러페이지 이미지 넣기
  const navigate = useNavigate();
  const [localDeviceSetting, setLocalDeviceSetting] = useLocalStorage(DEVICE_SETTING);

  useEffect(()=>{
    // 설정 정보 가져오기
    const deviceSetting = neverLandUtils.commons.load(DEVICE_SETTING);
    if(!deviceSetting) {neverLandUtils.commons.save(DEVICE_SETTING, DEVICE_SETTING_DEFAULT_LIST);}
  },[]);

  useEffect(() => {
    // code:프로젝트이름, deviceId:지점코드, pcId: pc 고유값(중복 로그인 방지, 모니터링용)
    // FIXME : 장치이름 받아와서 로그인 성공하면 기기인증 api
    window.login_device = (code, deviceId, pcId) => {
      try {
        fetcher.get(LOGIN_API + `?codeName=${deviceId}` + "&dt=" + new Date().getTime())
            .then(res => {
              const {responseIsSuccess, responseResultDescription} = res.data;
              if (responseIsSuccess) {
                neverLandUtils.commons.save(STORE_CODE, res.data.storeCodeName);
                neverLandUtils.commons.save(DEVICE_CODE, res.data.machineCodeName);
                neverLandUtils.commons.save(SET_MONEY_BUTTON_LIST, res.data.storeIsButtonActiveStatusList);
                forcedDeviceLoginCommit('성공');
                navigate(PROTO_GAME_PAGE + "?dt=" + new Date().getTime());
              } else {
                console.log(responseResultDescription);
                // navigate(ERROR_PAGE + `/${deviceId}` + "?dt=" + new Date().getTime());
                forcedDeviceLogout('잘못된 아이디입니다.');
              }
            })
            .catch((err) => {
              console.log(err);
              forcedDeviceLogout('잘못된 아이디입니다.(0x01:' + err.message + ')');
            })
      } catch (err2) {
        forcedDeviceLogout('잘못된 아이디입니다.(0x02:' + err2.message + ')');
      }
    };
  }, []);


  return (
      <>
        <WindowInterfaces/>

        <div className={`${accountInfo.isDarkMode() ? "bg-gray-900" : "bg-white"} w-full h-full  flex flex-col justify-center items-center`}>
          <Routes>
            <Route path={ERROR_PAGE} element={<ErrorPage/>}/>
            <Route path={SETTING_PAGE} element={<SettingPage/>}/>
            <Route path={LOGIN_PAGE + "/:id"} element={<LoginPage/>}/>
            <Route path={LOADING_PAGE} element={<LoadingPage/>}/>


            <Route path="/*" element={<MainPage/>}/>
            <Route path={MAIN_TEMP_PAGE} element={<MainPage/>}/>
            {/* "/" 경로로 들어오면 프로토 경기로 리다이렉트 */}
            <Route path="/" element={<Navigate to={PROTO_GAME_PAGE}/>}/>
          </Routes>
        </div>
      </>
  );
}

export default App;
