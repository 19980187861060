// <-- 기타 -->
export const LOGIN_API = '/management/machine/get/management_machine' // 로그인

// <-- 프로토 -->
export const LIST_OF_GAME_DATA_API = '/game/info/list/get/game_info_list' // 게임 데이터 가져오기

export const SEND_PRINT_INFO_API = '/game/print/list/set/game_print_list' // 출력할때 정보 넘겨주기

// <-- 프로토 -->
export const GET_TOTO_GAME_INFO_API = '/game/toto/list/get/game_toto_list' // 토토 경기정보 가져오기

// <-- 경기분석 -->
export const GET_SAME_RATE_INFO_LIST_API = '/game/info/list/get/filter/rate/game_info_list' // 동일 배당 경기 정보 불러오기


